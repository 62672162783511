import React from "react"
import { Link, graphql } from "gatsby"

import { Layout, SEO } from "../components"
import { connect } from "react-redux"
const BlogIndex = ({ data, location, currentCategory }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const byCategory = p => p.frontmatter.category === currentCategory

  const posts = currentCategory
    ? data.allMarkdownRemark.nodes.filter(byCategory)
    : data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Posts" />
        <div
          style={{
            marginTop: 24,
          }}
        >
          <p>
            Nenhum conteúdo legal no momento, mas em breve vou postar.
            <br />
            Obrigado pela visita :)
          </p>
        </div>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Posts" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    currentCategory: state.postsFilter.currentCategory,
  }
}
export default connect(mapStateToProps, null)(BlogIndex)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD [de] MMMM , YYYY", locale: "pt")
          title
          description
          category
        }
      }
    }
  }
`
